//controlaccess
import Service from "../Service";
import Vue from "vue";

const resource = "asterisk/";

export default {

    searchasterisk(obj, requestID) {
        return Service.post(resource + "searchasterisk", obj, {
            params: { requestID: requestID },
        });
    },
    actionsuccess(obj, requestID) {
        return Service.post(resource + "actionasterisk", obj, {
            params: { requestID: requestID },
        });
    },


    sendMail(requestID) {
        return Service.post(resource + "sendMail", {}, {
            params: { requestID: requestID },
        });
    },

    excel(obj, requestID) {
        return Service.post(resource + "excel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

}