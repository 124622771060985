<template>
<div>
    <v-container>
        <v-card outlined width="100%" style="margin-top:10px">
            <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri;font-size:20px">
                <b>Control de llamadas telefonicas</b>
                <v-spacer></v-spacer>
                <v-btn class="capitalize" width="100px" @click="btnSearch()" elevation="0" small color="info">Buscar</v-btn>
                <v-btn color="success" class="mt-0" icon small @click="excel">
                    <v-icon>far fa-file-excel</v-icon>
                </v-btn>
                <!-- <v-btn @click="sendmail()">sendmail</v-btn> -->
            </v-card-title>
        </v-card>
        <v-card outlined style="margin-top:10px;margin-bottom:10px" class="pb-1">
            <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-size:">
                <v-row justify="center">
                    <v-col cols="4">
                        <s-select-definition label="Tipo" :def="1174" return-object v-model="objState"></s-select-definition>
                    </v-col>
                    <v-col cols="3">
                        <s-date label="Fecha Inicio" v-model="filter.DateInitial"></s-date>
                    </v-col>
                    <v-col cols="3">
                        <s-date label="Fecha Fin" v-model="filter.DateEnd"></s-date>
                    </v-col>

                </v-row>
            </v-card-title>
        </v-card>

        <v-card outlined width="100%" style="margin-top:10px">
            <v-card-title style="margin-top: -10px; margin-bottom: -10px;font-size:14px">
                <b>Resultados de busqueda</b>
                <v-divider class="mt-3"></v-divider><v-btn @click="action()" small elevation="0">Update</v-btn>
                <v-col cols="12">
                    <v-data-table 
                        class="elevation-0 table table-hover table-condensed" 
                        height="260px" 
                        disable-sort 
                        disable-filtering 
                        dense 
                        :headers="headers" 
                        :items="items" 
                        item-key="uniqueid" 
                        v-model="selected" 
                        :single-select="singleSelect"
                        show-select
                        @click:row="rowClick"
                    >
                        <template v-slot:item.action="{ item }">
                            <v-btn icon v-if="item.action==1" >
                                <v-icon style="font-size:16px;color:green" >
                                    fas fa-check
                                </v-icon>
                            </v-btn>
                            <v-btn icon v-if="item.action==0">
                                <v-icon style="font-size:16px;color:red" >
                                    fa-solid fa-exclamation
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-card-title>
        </v-card>
        <!-- <v-row>

            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Contro llamadas Telefonicas" class="tran"></s-toolbar>
                <v-card outlined>

                    <v-row justify="center">
                        <v-col cols="12">
                            <v-card class="mx-auto my-1" width="100%">
                            </v-card>
                            <v-card outlined class="mx-auto my-1 pb-3">
                                <v-row justify="center" style="margin-top: 4px; margin-left: 10px;">
                                    <v-col cols="4">
                                        <s-select-definition label="Tipo" :def="1174" return-object v-model="objState"></s-select-definition>
                                    </v-col>
                                    <v-col cols="4">
                                        <s-date label="Fecha Inicio" v-model="filter.DateInitial"></s-date>
                                    </v-col>
                                    <v-col cols="4">
                                        <s-date label="Fecha Fin" v-model="filter.DateEnd"></s-date>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4">
                                        <v-btn @click="btnSearch()" elevation="0" small color="info">Buscar</v-btn>
                                        <v-btn color="success" class="mt-0" icon small @click="excel">
                                            <v-icon>far fa-file-excel</v-icon>
                                        </v-btn>
                                    </v-col>

                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-card outlined class="elevation-0">
                                <v-col style="padding: 10px">
                                    <v-data-table class="elevation-0 table table-hover table-condensed" height="260px" disable-sort disable-filtering dense :headers="headers" :items="items" item-key="uniqueid" v-model="selected" @click:row="rowClick">

                                    </v-data-table>
                                </v-col>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row> -->

        <v-dialog v-model="processing" v-if="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere.
                    <v-progress-linear indeterminate color="white" class="mb-0 mt-3"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</div>
</template>

<script>
import _sAsterisk from "@/services/Management/AsteriskService.js"

export default {

    components: {},
    data() {
        return {
            processing: false,
            messageProcessing: "",
            filter: {},
            headers: [
                
                {
                    text: "Act",
                    value: "action",
                    width: 20
                },
                {
                    text: "ID",
                    value: "uniqueid"
                },
                {
                    text: "Fecha",
                    value: "calldate"
                },
                {
                    text: "Identificador",
                    value: "clid"
                },
                {
                    text: "Nro. Entrante",
                    value: "cnum"
                },
                {
                    text: "Duración",
                    value: "duration"
                },
                {
                    text: "Estado",
                    value: "disposition"
                },
                /* {
                    text: "Canal",
                    value: "channel"
                } */
            ],
            items: [],
            objState: null,
            selected: [],
            singleSelect: false,
        };
    },

    watch: {

    },

    methods: {

        rowClick: function (item, row) {
            this.selected = [item];
            
        },

        sendmail(){

            _sAsterisk.sendMail(this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        console.log(r.data)
                        this.processing = false
                    }

                });
        },

        action(item){
            
            
            this.selected = this.selected.filter(x=> x.action == 0)
            if(this.selected.length < 1){
                return
            }

            this.processing = true
            _sAsterisk.actionsuccess(this.selected, this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        this.btnSearch()
                        this.processing = false
                    }

                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        },

        btnSearch() {
            this.filter.DateInitial = this.$moment(this.filter.DateInitial).format(this.$const.FormatDateTimeDB);
            this.filter.DateEnd = this.$moment(this.filter.DateEnd).format(this.$const.FormatDateTimeDB);

            this.filter.StateName = this.objState.DedDescription
            console.log(this.filter)
            this.processing = true
            _sAsterisk.searchasterisk(this.filter, this.$fun.getUserID()).then(resp => {
                    console.log("dataaaaaaaaaaaaaaa", resp)
                    if (resp.status == 200) {
                        resp.data.forEach(element => {
                            element.calldate = this.$moment(element.calldate).format(this.$const.FormatDateTimeDB);
                        });
                        this.items = resp.data
                        this.processing = false
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        },

        excel() {
            this.items.forEach(element => {
                console.log(element)
                element.Fecha = element.calldate
                element.date = new Date(element.calldate)
                element.Anio = new Date(element.calldate).getFullYear()
                element.Mes = new Date(element.calldate).getMonth() + 1
                element.Dia = new Date(element.calldate).getDate()
                element.Identificador = element.clid
                element.NroEntrante = element.cnum
                element.Duracion = element.duration
                element.Estado = element.disposition
            });

            console.log("excel",this.items);

            this.processing = true
            _sAsterisk.excel(this.items, this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "REPORTE");
                        this.processing = false
                    }

                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        },
    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>

